import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { loggedInRoute } from '../../core/config/routes.config';
import { ToasterService } from '../../core/services/visual/toaster.service';
import { AuthService } from '../auth.service';
import { LoginInterface } from './login.interface';
import { environment } from '../../../environments/environment';
import { getLanguageCode, setLanguageCode } from 'src/app/core/services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginLogo: string;
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    this.loginLogo = environment.loginLogo;
  }

  async onLogin(login: LoginInterface) {
    try {
      await this.authService.login(login.email, login.password);
      this.router.navigateByUrl(loggedInRoute);
    } catch (error) {
      this.toasterService.showErrorTranslating('auth.login.error');
    }
  }

  async changeLanguage(language: string) {
    this.translate.use(language);
    setLanguageCode(language);
  }

  get currentLanguage() {
    return getLanguageCode();
  }

}
